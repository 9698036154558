@import "../../styles/themes";

.option {
  --padding-start: 8px;
}

.typeIcon {
  margin-right: 8px;
}

.header {
  justify-content: flex-start;
}

.note {
  margin-left: 0;
}

