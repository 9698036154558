.Splash {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  background: #eaf4f9;

  padding-bottom: var(--ion-safe-area-top, 0);


  :global(.icon-selfory) {
    width: 75px;
    height: 75px;

    svg {
      overflow: visible;
    }
  }

  &.animate {
    animation: bg 2s 1 normal forwards;

    :global(.icon-selfory) {

      svg {
        animation: bothP 2s 1 normal forwards;
      }

      :global(.selfory-part-1) {
        animation: part1 2s 1 normal forwards;
      }

      :global(.selfory-part-2) {
        animation: part2 2s 1 normal forwards;

      }
    }
  }
}

@keyframes bg {
  0% {
    opacity: 1
  }
  80% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes bothP {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes part1 {
  0% {
    transform: translate(0, 0);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-432px, 108px);
  }
}

@keyframes part2 {
  0% {
    transform: translate(0, 0);
  }
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(432px, -108px);
  }
}