.ListFooter {
  padding-left: calc(16px + var(--ion-safe-area-left, 0));
  padding-right: calc(16px + var(--ion-safe-area-right, 0));
  color: rgba(0,0,0,.45);
  font-size: 14px;
}
:global(ion-list.list-ios) + .ListFooter {
  margin-top: -22px;
  margin-bottom: 32px;
}
:global(ion-list.list-md) + .ListFooter {
  margin-top: 10px;
  margin-bottom: 16px;
}