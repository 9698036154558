@import "styles/variables";

.Years {
  display: flex;
  position: absolute;
  top:0;
  //padding-top: var(--ion-safe-area-top, 0);
  padding:0;
  margin: 0;
  z-index: 5;
  width: 100%;
  overflow: hidden;
  background: $color-background;
  user-select: none;
  pointer-events: none;
  transition: transform .2s;

  //&.detailed {
  //  transform: translate(0, -10px);
  //}

  .inner {
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    padding-inline-start: 0;
    width: auto;
    flex: 1;
    margin: 0;
    height: $header_height;
  }

  .grid {
    height: 50%;
    bottom: 0;
    top: unset;

    &.zoom-1 {
      line {
        stroke: transparentize(darken($color-background, 20%), .5);

        &:nth-child(4n) {
          stroke: transparentize(darken($color-background, 40%), .5);
        }
      }
    }

    &.zoom-2 {
      line {
        stroke: transparentize(darken($color-background, 20%), .5);

        &:nth-child(12n) {
          stroke: transparentize(darken($color-background, 40%), .5);
        }
      }
    }
  }

  .todayLine {
    z-index: 5;

    line {
      stroke: rgba(255, 151, 151, 0.5);
      //stroke: red;
      z-index: 5;
    }
  }

  .seekerLine {
    z-index: 3;

    line {
      stroke: rgba(20, 148, 229, 0.33);
      stroke-dasharray: 0;
      z-index: 5;
    }
  }

  li {
    flex: 0 0 auto;
    height: 40px;
    border: none;
    box-shadow: -1px 0 0 0 $color-year-title;
    text-align: left;
    padding: 20px 10px 5px;
    // margin-bottom: 20px;
    color: $color-year-title;
    // margin: 0;
    font-size: 14px;
    font-weight: 600;
    @include transition-resize();

    .month {
      display: none;
    }
  }

  &.zoom-2 li {
    display:flex;
    flex-flow: row wrap;
    align-items: stretch;
    padding-left: 0;
    padding-right: 0;

    .year {
      width: 100%;
      margin-top: -12px;
      font-size: 12px;
      font-weight: bold;
      padding-left: 10px;
      color: darken($color-year-title, 10%);
    }
    .month {
      display: flex;
      font-size: 12px;
      padding-left: 10px;
      flex: 1 0 auto;
      color: lighten($color-year-title, 10%);

      &:first-child {
        display: none;
      }
    }
  }
}