@import "styles/variables";
@import "styles/themes";

.PeriodSticker {
  width: 100%;
  flex: 0 0 auto;
  height: var(--period-size);
  // background: blue;
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;


  &.right,
  &.almost_right{
    justify-content: flex-end;
  }

  .preview {
    //position: absolute;
    width: calc(var(--period-size) / 1.5);
    height: calc(var(--period-size) / 1.5);
    border-radius: calc(var(--period-size) / 2);
    //left: 100%;
    //top: 5px;
    background: #fff;
    overflow: visible;
    @include transition-resize();
    opacity: 0;
    transition: opacity .2s;
    pointer-events: auto;

    font-size: 12px;
    color: $color-period-text;

    display: flex;
    align-items: center;
    justify-content: center;

    //text-indent: -1000px;
  }

  .title {
    //position: absolute;
    //top: 3px;
    //left: 33px;
    font-size: 14px;
    color: $color-period;
    padding: 0 10px;
    height: calc(var(--period-size) - 6px);
    align-items: center;
    display:flex;
    // max-width:100%;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;

    max-width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    opacity: 0;
    transition: opacity .2s;
    text-indent: -1000px;
  }

  &.left {
    .preview {
      opacity: 1;
      //left: 0;
    }
    .title {
      opacity: 1;
      text-indent: 0;
    }
  }
  &.almost_left {
    .preview {
      opacity: .3;
      //left: 0;
    }
    .title {
      opacity: 1;
      text-indent: 0;
    }
  }
  &.right {
    .preview {
      opacity: 1;
      order: 1;
    }
    .title {
      opacity: 1;
      text-indent: 0;
    }
  }
  &.almost_right {
    .preview {
      opacity: .3;
      order: 1;
    }
    .title {
      opacity: 1;
      text-indent: 0;
    }
  }
  &.inside {
    .preview {
      display: none;
    }
    .title {
      opacity: 0;
      text-indent: 0;
      padding-right: 30px;
      margin-left: -10px;
      //transition: opacity .2s .2s;
    }

    &.inside.scrolling .title {
      opacity: 1;
    }

  }
  //&.inside_right {
  //  justify-content: flex-end;
  //  .preview {
  //    display: none;
  //  }
  //  .title {
  //    opacity: 1;
  //    text-indent: 0;
  //    padding-left: 30px;
  //    margin-right: -10px;
  //    transition: opacity .2s .2s;
  //  }
  //}
  &.none {
    .title {
      opacity: 0;
      text-indent: -1000px;
    }
  }
}

@include themifyGlobal() {
  .PeriodSticker .preview {
    background: transparentize(themed("primary"), .4);
    color: themed("secondary");

  }
  .PeriodSticker/*:not(.inside)*/ .title {
    color: darken(themed("secondary"), 10%);
  }
  .PeriodSticker.inside_left .title {
    background: linear-gradient(90deg, transparentize(themed("primary"), .1) calc(100% - 20px), transparentize(themed("primary"), 1) 100%);
    //background: themed("primary");
    //background: transparentize(themed("primary"), .1);

    //border-radius: 0 20px 20px 0;
  }
  .PeriodSticker.inside_right .title {
    background: linear-gradient(90deg, transparentize(themed("primary"), 1) 0, transparentize(themed("primary"), .1) 20px);
  }

  .PeriodSticker.inside .title {
    background: linear-gradient(90deg, transparentize(themed("primary"), .1) calc(100% - 20px), transparentize(themed("primary"), 1) 100%);
  }
}