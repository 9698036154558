@import "styles/variables";

.ScrollBars {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  left: 0;
  top: 0;

  line {
    stroke-width: 7;
    stroke: $color-scrollbar;
    opacity: 0;
    transition: opacity .5s;
  }

  &.scrolling line {
    opacity: 1;
    transition: opacity .2s;
  }

  line.background {
    stroke: transparentize(lighten($color-background, 20%), .5);
  }
}