@import "styles/variables";

.YearsSticker{
  position: absolute;
  left: 0;
  //top: var(--ion-safe-area-top, 0);
  top:0;
  // min-width: 40px;
  height: 40px;
  z-index: 5;
  padding: 20px 0px 5px 10px;
  color: $color-year-title;
  font-size: 14px;
  font-weight: 600;
  background: $color-background;
  transition: transform .2s;
  opacity: 1;

  //&.detailed {
  //  transform: translate(0, -10px);
  //}

  &.hidden {
    opacity: 0;
    transition: opacity .1s ease;
  }

  &.zoomed {
    margin-top: -12px;
    font-size: 12px;
    font-weight: bold;
    padding: 20px 0 0 10px;
    height: auto;
    color: darken($color-year-title, 10%);
  }
}