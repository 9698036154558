@import "styles/themes";

.option {
  --color: #6a81a3;
  color: #6a81a3;
  --padding-start: 8px;
}

.colorIcon {
  margin-right: 8px;
}

.label {
  @include themifyGlobal {
    color: themed("secondary");
  }
}

.note {
  margin-left: -4px;
}

