.PeriodViewer {
  top: calc(150px + var(--ion-safe-area-top, 0));
  transition: top .4s;

  contain: none;

  &:global(.full) {
    top: var(--ion-safe-area-top, 0);
  }

  :global(.modal-wrapper),
  :global(.ion-page) {
    overflow: visible;
  }

  :global(.ion-page) {
    box-shadow: 0 -2px 3px rgba(0,0,0,.1);
  }

  :global(.swiper-pagination) {
    top: 0;
    bottom: unset;
  }

  :global(.icon-triangle) {
    display: block;
    position: absolute;
    width: 20px;
    height: 10px;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 11;

    svg {
      filter: drop-shadow(0px -4px 3px rgba(0, 0, 0, 0.15));
    }
  }

  .title {
    --color: var(--ion-color-medium);

    .subtitle {
      font-size: 14px;
      margin: 0;
    }
  }

  .toolbar {
    padding-top: 0;
  }


}
