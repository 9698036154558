@import "styles/variables";
@import "styles/themes";

.Categories {
  width: 100%;
  //height: 100%;
  overflow: hidden;
  list-style: none;
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 40px 0 0;
  padding-inline-start: 0px;
  pointer-events: none;
  margin-top:var(--ion-safe-area-top, 0);

  li {
    // position: absolute;
    padding: 30px 0 10px;
    position: relative;

    left:0;
    top:0;

    .title {
      display: block;
      position: absolute;
      top: 10px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: $color-category-title;
      text-shadow: 0 1px 1px white;
      margin-bottom: 3px;
      padding-left: 10px;
    }

    @include themifyGlobal() {
      //background: transparentize(lighten(themed('primary'), 5%), .5);

      .title {
        color: themed("secondary")
      }
    }
  }
}