@import "styles/variables";
@import "styles/themes";

.Period {
  width: 100%;
  flex: 0 0 auto;
  padding: 15px 0;
  // background: blue;
  position: relative;
  overflow: hidden;
  height: var(--period-size);
  transition: height .5s;

  .view {
    position: absolute;
    min-width: calc(var(--period-size) - 6px);
    height: calc(var(--period-size) - 6px);
    border-radius: calc(var(--period-size) / 2);
    top: 3px;
    left:0;
    background: $color-period;
    display: flex;
    align-items: center;
    @include transition-resize();
    //  overflow: hidden;
    // text-indent: -10000px;

    overflow: hidden;

    .title {
      //position: absolute;
      font-size: 14px;
      color: $color-period-text;
      //bottom: 100%;
      //top: 50%;
      //transform: translateY(-50%);
      //margin-top: -7px;
      padding: 0 15px;
      position: absolute;
      //max-width:100%;
      white-space: nowrap;
      //text-overflow: ellipsis;
      //overflow: hidden;

      display: flex;
      align-items: center;
      height: 100%;

      //transition: opacity .2s;

      //&:after {
      //  content: " ";
      //  display: block;
      //  width: 10px;
      //  height: 1px;
      //  border-bottom: 1px dashed #aaa;
      //  position: absolute;
      //  left: 0;
      //  top: 50%;
      //  margin-top: -1px;
      //}

      &.hidden {
        opacity: 0;
      }
      //&.left {
      //  right: 100%;
      //
      //  &:after {
      //    right: 0;
      //    left: unset;
      //  }
      //}
      //&.right {
      //  left: 100%;
      //}

      &.over {
        z-index: 4;
      }
    }

    &.now {
      border-radius: calc(var(--period-size) / 2) 0 0 calc(var(--period-size) / 2);

      &:after {
        content: " ";
        display: block;
        width: 1200px;
        height: inherit;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 100%;
        background: darken($lightgrey, 5%);
        border: 1px dotted transparentize($color-period, 0.3);
        border-radius: 0 15px 15px 0;
      }
    }
  }
}

@include themifyGlobal() {
  .view {
    background: transparentize(themed("primary"), .2);

    .title {
      //text-shadow: 0 1px 1px themed("secondary");
      color: darken(themed("secondary"), 10%);

      &:after {
        border-color: themed("secondary");
      }

      &.over {
        background: linear-gradient(90deg, transparentize(themed("primary"), .1) calc(100% - 20px), transparentize(themed("primary"), 1) 100%);
        transition: z-index .2s .4s, opacity .2s .4s;
      }
    }
  }
};