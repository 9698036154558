:global(.toolbar-container) {
  transition: padding-top .2s, padding-bottom .2s;
}

.TimelinePage {


  .header {

    .toolbar {
      --min-height: 0px;
      --padding-top: 0;
      --padding-bottom: 0;


    }

    :global(.icon-triangle) {
      visibility: hidden;
    }

    &.detailed {
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

      .toolbar {
        --min-height: 24px;
        --padding-top: 3;
        --padding-bottom: 3;
      }
      :global(.icon-triangle) {
        visibility: visible;
      }
    }
  }
  .title {
    font-size: 13px;
  }

  :global(.icon-triangle) {
    display: block;
    position: absolute;
    width: 20px;
    height: 10px;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -1px) rotate(180deg);
    z-index: 11;

    svg {
      filter: drop-shadow(0px -3px 2px rgba(0, 0, 0, 0.15));
    }
  }

}