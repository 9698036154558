@import "styles/variables";

.Timeline {
  width: 100%;
  height: 100%;
  position: absolute;
  //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
  flex: 1 1 auto;

  align-items: flex-start;

  overflow: hidden;

  // DO NOT REMOVE THIS, WILL CAUSE RENDER PROBLEMS ON CHROME
  transform: translateZ(0);

  display: flex;
  flex-flow: column nowrap;

  overscroll-behavior: none;

  scroll-padding: 40px 0 0 0;

  .canvas {
    position: relative;
    flex: 1 0 auto;
    min-width: 100%;
    min-height: 100%;
    display: block;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: var(--ion-safe-area-top, 0);
    z-index: 3;
  }

  .category {
    width: 100%;
    //min-height: 100px;
    // height: 100px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    padding: 30px 0 10px;
    z-index: 2;
    //background: #ccc !important;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
  }

  .todayLine {
    z-index: 5;

    line {
      stroke: rgba(255, 151, 151, 0.5);
      //stroke: red;
      z-index: 5;
    }
  }

  .seekerLine {
    z-index: 3;

    line {
      stroke: rgba(20, 148, 229, 0.33);
      stroke-dasharray: 0;
      z-index: 5;
    }
  }

  .grid {
    &.zoom-1 {
      line {
        stroke: transparentize(darken($color-background, 20%), .5);

        &:nth-child(4n) {
          stroke: transparentize(darken($color-background, 40%), .5);
        }
      }
    }

    &.zoom-2 {
      line {
        stroke: transparentize(darken($color-background, 20%), .5);

        &:nth-child(12n) {
          stroke: transparentize(darken($color-background, 40%), .5);
        }
      }
    }
  }
}
