@import "styles/variables";
@import "styles/themes";

.Memory {
  width: calc(var(--period-size) - 10px);
  height: calc(var(--period-size) - 10px);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  //@include transition-resize();

  & .image {
    width: 100%;
    height: 100%;
    position: absolute;
    border: 1px solid $color-period;
    border-radius: 5px;
    left: 0;
    top: 0;
    background-color: lighten($color-period, 10%);
    background-position: center center;
    background-size: cover;
    z-index: 1;

  }

  &.size-1,
  &.size-2 {
    width: 2px;
    height: calc(var(--period-size) - 6px);
    opacity: .5;
    .image {
      background-image: none !important;
    }
  }

  &.size-1 {
    //transform: translate(-50%, -50%) scale(.2);
  }

  &.size-2 {
    //transform: translate(-50%, -50%) scale(.5);
  }

  &.size-3 {
    //transform: translate(-50%, -50%) scale(.9);
  }

  &.size-4 {
    //transform: translate(-50%, -50%) scale(1);
  }

  &.size-5 {
    //transform: translate(-50%, -50%) scale(1.1);
  }

  //&:after {
  //  content: ' ';
  //  display: block;
  //  position: absolute;
  //  z-index: 0;
  //  top: 100%;
  //  // left: -7px;
  //  left: 50%;
  //  margin-left: -0.5px;
  //  width: 1px;
  //  height: 10px;
  //  background: #007bff;
  //}

  &.alone {
    //&:before {
    //  content: ' ';
    //  display: block;
    //  position: absolute;
    //  z-index: 0;
    //  top: 30px;
    //  left: 50%;
    //  margin-left: -3.5px;
    //  width: 7px;
    //  height: 7px;
    //  border-radius: 7px;
    //  // transform: rotate(45deg);
    //  box-shadow: inset 0 0 0 2px $color-period;
    //  background: #fff;
    //  z-index: 1;
    //}
  }

  // &:after {
  //   content: ' ';
  //   display: block;
  //   position: absolute;
  //   z-index: 0;
  //   top: 19px;
  //   // left: -7px;
  //   left: 1px;
  //   width: 0;
  //   height: 0;
  //   border-style: solid;
  //   border-width: 12.5px 11.5px 0 11.5px;
  //   border-color: #abc transparent transparent transparent;
  //   border-radius: 25px;
  // }
}

@include themifyGlobal() {
  .image {
    //border: 1px solid themed("primary");
    border: 2px solid themed("secondary");
    //background-color: lighten(themed("primary"), 10%);
    background-color: themed("secondary");
  }
}