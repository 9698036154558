@import "styles/temp_utils";
@import "styles/variables";
@import "styles/themes";

@include themify(".ion-color-") {
  @include generateColor(themed("primary"), themed("secondary"));
}

.ion-color-white {
  --ion-color-base: #FFF;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-contrast: #000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #FFF;
  --ion-color-tint: #FFF;
}

.ion-color-global {
  @include generateColor($color-global);
}

.ios ion-list:first-child:last-child{
  margin-bottom: 0;
}

ion-icon {
  stroke: currentColor;
  fill: currentColor;
}

.ion-grid-input {
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
}

// special margin for first list in form
.outer-content {
  ion-list:first-child,
  ion-list-header:first-child{
    margin-top: 16px;
  }
  ion-list + ion-list-header {
    margin-top: -16px;
  }
}