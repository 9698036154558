$header_height: 40px;

$darkgrey: #7D8592;
$grey: #938E94;
$lightgrey: #f7f9fb;
$light: #eaf4f9;

$color-background: $light;

$color-category-title:$grey;
//$color-category-title: #444;
$color-year-title:darken($grey, 10%);
//$color-year-title:#333;

$color-grid: transparentize($grey, .8);

$color-period: #85c5f1;
$color-scrollbar: transparentize(#1494e5, .5);
//$color-period-text: #6194DF;
//$color-period-text: $light;
$color-period-text: #fff;

$color-global: #6a81a3;

@mixin transition-resize() {
  transition: width 0.2s ease, left 0.2s ease, transform 0.2 ease;
}

@mixin generateColor($color, $contrast: null) {
  @if not ($contrast) {
    $contrast: $color;
  }

  --ion-color-base: #{$color};
  --ion-color-base-rgb: #{red($color)}, #{green($color)}, #{blue($color)};
  --ion-color-contrast: #{$contrast};
  --ion-color-contrast-rgb: #{red($contrast)}, #{green($contrast)}, #{blue($contrast)};
  --ion-color-shade: #{darken($color, 10%)};
  --ion-color-tint: #{lighten($color, 10%)};
}