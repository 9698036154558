@import "styles/variables";
@import "styles/themes";

.CategoriesSticker {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(var(--ion-safe-area-top, 0) + #{$header_height});
  z-index: 7;

  text-transform: uppercase;
  font-size: 12px;
  color: $color-category-title;
  padding: 5px 0 0 10px;
  text-shadow: 0 1px 1px white;
  font-weight: 600;

  transition: none;
  opacity: 1;

  &.hidden {
    opacity: 0;
    transition: opacity .1s ease;
    text-indent: -1000px;
  }

  @include themifyGlobal {
    color: themed("secondary");
  }
}