@import "styles/variables";

.Grid {
  width: 100%;
  height: 100%;
  position: absolute;
  left:0;
  top:0;

  line {
    stroke: transparentize(darken($color-background, 40%), .5);
    stroke-dasharray: 2;
  }
}