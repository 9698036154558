@import "styles/themes";

.label {
  @include themify() {
    color: themed("secondary");
  }
}

.colorIcon {
  margin-right: 8px;
}

.note {
  margin-left: -4px;
}

