$themes: (
  pastel-pink: (
    primary: #ffcdd2,
    secondary: #CB9CA1
  ),
  chantilly: (
    primary: #f8bbd0,
    secondary: #C48B9F,
  ),
  french-lilac: (
    primary: #e1bee7,
    secondary: #AF8EB5
  ),
  prelude: (
    primary: #d1c4e9,
    secondary: #A094B7
  ),
  periwinkle-gray: (
    primary: #c5cae9,
    secondary: #9499B7
  ),
  sail: (
    primary: #bbdefb,
    secondary: #8AACC8
  ),
  french-pass: (
    primary: #b3e5fc,
    secondary: #82B3C9
  ),
  blizzard-blue: (
    primary: #b2ebf2,
    secondary: #81B9BF
  ),
  aqua-island: (
    primary: #b2dfdb,
    secondary: #82ADA9
  ),
  zanah: (
    primary: #c8e6c9,
    secondary: #97B498
  ),
  chrome-white: (
    primary: #dcedc8,
    secondary: #AABB97
  ),
  feijoa: (
    primary: #aed581,
    secondary: #94AF76
  ),
  tusk: (
    primary: #f0f4c3,
    secondary: #BDC192
  ),
  manz: (
    primary: #dce775,
    secondary: #B3BC6D
  ),
  lemon-chiffon: (
    primary: #fff9c4,
    secondary: #CBC693
  ),
  paris-daisy: (
    primary: #fff176,
    secondary: #CBC26D
  ),
  buttermilk: (
    primary: #ffecb3,
    secondary: #CBBA83
  ),
  frangipani: (
    primary: #ffe0b2,
    secondary: #CBAE82
  ),
  romantic: (
    primary: #ffccbc,
    secondary: #CB9B8C
  ),
);

@mixin themify($prefix: "&.theme-", $themes: $themes) {
  $before: "";
  $after: "";
  @if (type-of($prefix) == list) {
    $before: nth($prefix, 1);
    $after: nth($prefix, 2);
  } @else if (type-of($prefix) == string){
    $before: $prefix;
  }

  @each $theme, $map in $themes {

    #{$before}#{$theme}#{$after} {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@mixin themifyGlobal($prefix: "&") {
  @include themify(("#{$prefix}:global(.theme-", ")")) {
    @content
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}