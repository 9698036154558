.ActionIcon {
  display: inline-block;

  .button {
    &.info, &.help {
      --padding-start: 4px;
      --padding-end: 4px;
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
      margin-bottom: -2px;
    }

    &.close, &.cross {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
  }
}